import React from "react"
import LocalizedLink from "../components/localizedLink";
import SEO from "../components/seo"
import './index.css'
import { graphql } from 'gatsby';

const PostsPage = ({ data: { allMdx } }) => {

  return (
    <article className="gridContainer">
      <SEO title="Posts" />
      
      {renderPosts(allMdx)}
    </article>
  )
}

export const query = graphql`
  query PostsPage($locale: String!, $dateFormat: String!) {
    allMdx(
      filter: { fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: $dateFormat)
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }`

function renderPosts(allMdx) {
  return <ul className="post-list">
    {allMdx.edges.map(({ node: post }) => (<li key={`${post.frontmatter.title}-${post.fields.locale}`}>
      <LocalizedLink to={`/${post.parent.relativeDirectory}`}>
        {post.frontmatter.title}
      </LocalizedLink>
      <div>{post.frontmatter.date}</div>
    </li>))}
  </ul>
}


export default PostsPage;